.title {
  color: #007BFF;
  text-align: center;
  margin-bottom: 20px;
}

/* Cart Page */
.cart-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  margin-top:90px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.item-info h3 {
  color: #007BFF;
  margin: 0;
}

.item-price {
  color: #28a745;
  font-weight: bold;
}

.remove-button {
  background-color: #dc3545;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.remove-button:hover {
  background-color: #c82333;
}

.checkout-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #28a745;
  color: #ffffff;
  text-decoration: none;
  border-radius: 4px;
  text-align: center;
}

.checkout-button:hover {
  background-color: #218838;
}

/* Checkout Page */
.checkout-container {
  margin-top: 90px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.checkout-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  color: #007BFF;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.error-message {
  color: #dc3545;
  font-size: 12px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #218838;
}
